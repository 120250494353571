import {AddWindowUrlParams, ImageUrl} from "../../../Helpers/Url";
import React, {useState} from 'react';
import {usePopper} from "react-popper";
import AddFavorites from "../../../Components/AddFavorite";
import {Arrow, Compare, Hearth} from "../../../Helpers/Icons";

let classNames = require('classnames');
const axios = require('axios');

export default function ComparisonsOverview(props) {

    let favorites = JSON.parse(props.favorites);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        modifiers: [{name: 'arrow', options: {element: arrowElement}}, {
            name: 'offset',
            options: {
                offset: [10, 10],
            },
        }],
        placement: 'bottom-end'
    });
    const [tooltipHover, setTooltipHover] = useState(false);


    const onSubmit = (event) => {
    };

    return (
        <React.Fragment>

            <div className="row">

                {Object.keys(favorites).map(function (key) {
                    return <div className="col-12 col-tablet-3 my-20" key={key}>


                    </div>
                })}
            </div>

        </React.Fragment>
    )
}
