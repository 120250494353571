import FormText from "../Components/inputs/text";
import React, {useState} from 'react';
import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Alert from "../../../Components/Alert";
import FormPassword from "../Components/inputs/password";
import Link from "../../../Components/Link";

const axios = require('axios');

export default function AccountPassword(props) {

    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [user, setUser] = useState(window.user);
    const showButton = Object.keys(user).length >= 1;

    const handleChange = (event) => setUser({...user, ...{[event.target.name]: event.target.value}});

    const onSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(null);
        setErrorBag({});
        setLoading(true);

        axios.post(window.account_update, {
            email: user.email,
            password: password.value,
        }).then(function (response) {
            if (response.data) {
                if (response.data.hasOwnProperty('message')) {
                    setSuccessMessage(response.data.message);
                }

                if (response.data.hasOwnProperty('url')) {
                    window.location.href = response.data.url;
                    return;
                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;

                if (data.hasOwnProperty('message')) {
                    setErrorBag(data.message);
                }
            }
        });
    };

    return (
        <React.Fragment>

            <form onSubmit={onSubmit}>
                <div className="dashboard-tile dashboard-tile--white px-30 pt-10 pb-30 bg-white">
                    {window.redirectUrl &&
                        <div className="ml-n15 my-20">
                            <Link
                                onClick={() => window.location = window.redirectUrl}
                                title="terug"
                                arrow={true}
                                arrowRight={false}
                                color="primary"
                            />
                        </div>
                    }

                    <h1 className="text--h1 font-weight-bold text-primary-60 my-20">
                        Uw wachtwoord wijzigen
                    </h1>

                    <ErrorBag errors={errorBag}/>
                    <Alert message={successMessage} color="green" isActive={!!successMessage}/>

                    <div className="my-20">
                        <FormPassword
                            onChange={handleChange}
                            title="Nieuw wachtwoord"
                            value={user.password ? user.password : ''}
                            name="password"
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="link-holder mt-n20 d-flex justify-content-center">
                    <Button
                        title="Wijzigen"
                        color="yellow"
                        disabled={!showButton}
                        type="submit"
                        arrow={true}
                        isLoading={loading}
                    />
                </div>
            </form>
        </React.Fragment>
    )
}
