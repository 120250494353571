import $ from "jquery";

const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;

let timer = null;

const handleCountdown = function (countDownDate, dateField, counter) {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // If the count down is over
    if (distance < 0) {
        clearInterval(timer);
    } else {
        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / day);
        const hours = Math.floor((distance % day) / hour);
        const minutes = Math.floor((distance % hour) / minute);

        const countdown = {
            dagen: days,
            uur: hours,
            minuten: minutes
        };

        for (let key in countdown) {
            let $el = $('#' + key);
            $el.find('.time').html(countdown[key]);
        }
    }
}

const calculatePercentage = function (countdown) {
    const percentDays = (countdown.dagen / 365);
    const percentHours = (countdown.uur / 24);
    const percentMinutes = (countdown.minuten / 60);

    const percentages = {
        dagen: percentDays,
        uur: percentHours,
        minuten: percentMinutes
    };
};

export default function Countdown()
{
    const dateField = document.querySelector('.date');
    const dateTime = $(dateField).data('date');

    if (dateTime) {
        const countDownDate = new Date(dateTime.replace(/-/g, "/")).getTime();

        // Update the count down every 1 second
        timer = setInterval(handleCountdown, 1000, countDownDate, dateField);
    }
}

