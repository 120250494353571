import React from 'react';
import {UserProvider} from "../../../Context/userProvider";
import AddFavorites from "../../../Components/AddFavorite";
import {Hearth} from "../../../Components/Icons";

let classNames = require('classnames');

export default function FavApp({type, typeId}) {
    return (
        <div>
            <UserProvider>
                <AddFavorites id={typeId} type={type}>
                    {(active) => (
                        <div>
                            <div
                                className={classNames('action-button action-button--blue my-10 ml-auto', {'is-active': active})}>
                                <span>Bewaar</span>
                                <Hearth/>
                            </div>
                        </div>
                    )}
                </AddFavorites>
            </UserProvider>
        </div>
    )
}
