export default class {
    constructor(element, trigger) {
        this.trigger = trigger;
        this.card = element;
        this.popupClose = $(element).find('.popup__close')[0];
        this.popupUnderlay = $(element).find('.popup__underlay')[0];
        if (this.card && this.popupClose && this.popupUnderlay) {
            this.init();
        }
    }

    popup() {
        this.togglePopUp(this.card);
    }

    togglePopUp(cardElement) {
        if (!cardElement.classList.contains('active')) {
            document.body.classList.add('is-locked');
            cardElement.classList.add('active');
        } else {
            document.body.classList.remove('is-locked');
            cardElement.classList.remove('active');
        }
    }

    closePopup() {
        document.body.classList.remove('is-locked');
        this.card.classList.remove('active');
    }

    init() {
        if (this.trigger) {
            this.trigger.addEventListener('click', (e) => this.popup());
        }
        if (this.popupClose) {
            this.popupClose.addEventListener('click', (e) => this.closePopup());
        }
        if (this.popupUnderlay) {
            this.popupUnderlay.addEventListener('click', (e) => this.closePopup());
        }
    }
}
