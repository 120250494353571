import React from 'react';
import {UserProvider} from "../../../Context/userProvider";
import AddComparisons from "../../../Components/AddComparison";
import {Compare, Arrow} from "../../../Components/Icons";

let classNames = require('classnames');

export default function CompareApp({type, typeId}) {
    return (
        <div>
            <UserProvider>
                <AddComparisons id={typeId} type={type}>
                    {(active) => (
                        <div>
                            <div
                                className={classNames('action-button action-button--primary my-10 ml-auto', {'is-active': active})}>
                                <span>Vergelijken</span>
                                <Compare/>
                            </div>
                        </div>
                    )}
                </AddComparisons>
            </UserProvider>
        </div>
    )
}
