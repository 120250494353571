import {createPopper} from "@popperjs/core";

const tooltipGenerator = function (tooltips) {
    for (let i = 0; i < tooltips.length; i++) {
        let wrapper = tooltips[i];
        let item = wrapper.querySelector('.tooltipper');
        let tooltip = wrapper.querySelector('.tooltip');

        createPopper(item, tooltip, {
            placement: 'bottom-end',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [10, 10],
                    },
                },
            ],
        });

        showTooltip(item, tooltip);
        hideTooltip(item, tooltip);
    }
}

const hideTooltip = (item, tooltip) => {
    const hideEvents = ['mouseleave', 'blur'];
    hideEvents.forEach(event => {
        item.addEventListener(event, () => {
            tooltip.removeAttribute('data-show');

        });
    });
};

const showTooltip = (item, tooltip) => {
    const showEvents = ['mouseenter', 'focus'];
    showEvents.forEach(event => {
        item.addEventListener(event, () => {
            tooltip.setAttribute('data-show', '');
        });
    });
};

export default tooltipGenerator;
