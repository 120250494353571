import inputAnimation from './modules/inputAnimation';
import {LazyImages} from "./modules/lazyImages";
import Swiper from 'swiper/js/swiper';
import {FormValidation} from "./modules/FormValidation";
import toggleGenerate from './modules/toggleGenerate';
import tooltipGenerator from "./modules/tooltip";
import popupGenerate from "./modules/popupGenerate";
import Menu from "./modules/menu";
import Accordion from "./modules/Accordion";
import Countdown from "./countdown/countdown";
import {FormFilterClass} from "./modules/FormFilterClass";
import Colcade from "colcade/colcade";
import Tracker from "./modules/analytics";

require('lity');

const tracker = new Tracker('data/track');

const togglePassword = ($input) => {
    $input.type = $input.type === 'password' ? 'text' : 'password';
};

function trackData($element) {
    const data = $element.data('trackerObject');
    const trackerAction = $element.data('trackerAction');
    const trackerType = $element.data('trackerType') ?? 'event';

    tracker.trackData(trackerType, trackerAction, data);

}

$(function () {
    new Menu();
    new inputAnimation();
    new LazyImages($('body'));
    new Countdown();

    // Info swiper
    new Swiper('.swiper-container-info', {
        slidesPerView: 1,
        spaceBetween: 20,
        keyboard: {
            enabled: true,
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        speed: 800,
        loop: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1080: {
                slidesPerView: 4,
            }
        },
        navigation: {
            nextEl: '.swiper-info-button-next',
            prevEl: '.swiper-info-button-prev',
        },
    });

    // Teacher shiper
    new Swiper('.swiper-container-teachers', {
        spaceBetween: 40,
        navigation: {
            nextEl: '.swiper-teacher-button-next',
            prevEl: '.swiper-teacher-button-prev',
        },
    });

    // ProgramInfo swiper
    new Swiper('.swiper-container-programinfo', {
        spaceBetween: 20,
        slidesPerView: 1.5,
        breakpoints: {
            768: {
                slidesPerView: 2.5,
            },
            960: {
                slidesPerView: 3.5,
            },
            1080: {
                slidesPerView: 4,
            }
        },
    });

    // Info swiper
    new Swiper('.swiper-container-review', {
        slidesPerView: 1,
        spaceBetween: 20,
        keyboard: {
            enabled: true,
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        speed: 800,
        loop: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
        },
        navigation: {
            nextEl: '.swiper-review-button-next',
            prevEl: '.swiper-review-button-prev',
        },
    });


    // Form Validation
    let forms = document.getElementsByClassName('form');
    for (let i = 0; i < forms.length; i++) {
        let form = forms[i];
        let $form = $(form);
        let authorized_signatory_amount = $form.find('button[name=authorized_signatory_amount]');
        let remove_authorized_signatory = $form.find('button[name=authorized_signatory_remove]');

        if (authorized_signatory_amount.length) {
            let authorized_signatory_template = document.querySelector('.authorized-signatory-template');
            let item = authorized_signatory_template.querySelector('.authorized-signatory-item');
            let authorized_signatory_row = document.querySelector('.authorized-signatory-row');
            let wrapper = authorized_signatory_row.querySelector('.authorized-signatory-wrapper');

            let currentForm = 0;

            if (remove_authorized_signatory.length) {
                $(remove_authorized_signatory).on('click', function () {
                    if (currentForm > 0) {
                        let itemId = document.getElementById('authorized_signatory_' + currentForm);

                        if (itemId) {
                            $(wrapper).find(itemId).remove();
                            currentForm--;
                        }
                    }

                    if (currentForm < 9) {
                        $(authorized_signatory_amount).prop("disabled", false);
                    }

                    displayRemoveBtn(remove_authorized_signatory, currentForm);
                });
            }

            $(authorized_signatory_amount).on('click', function () {
                if (currentForm < 9) {
                    currentForm++;

                    displayRemoveBtn(remove_authorized_signatory, currentForm);
                    item.firstElementChild.id = "authorized_signatory_" + currentForm;

                    // Show authorized signatory count inside title
                    if (currentForm <= 1) {
                        let first = form.querySelectorAll('.authorized-signatory-count')[0];
                        $(first).html(currentForm);
                    }

                    let count = item.querySelector('.authorized-signatory-count');
                    $(count).html((currentForm + 1));

                    let title = item.querySelector('[data-authorized-title]');
                    $(title).text(title.getAttribute('data-authorized-title'));

                    let authorizedSelectParent = item.querySelector('[data-authorized-select]');
                    let authorizedSelect = authorizedSelectParent.querySelector('select');

                    if (authorizedSelect) {
                        $(authorizedSelectParent).html(`<input type='hidden' name='${authorizedSelect.name}' value='${authorizedSelectParent.getAttribute('data-authorized-select')}'/>`);
                    }

                    authorized_signatory_row.classList.add('active');
                    let html = $(item).html();
                    html = html.replace(/\[\$i\]/g, `[${currentForm}]`);
                    $(wrapper).append(html);
                    new inputAnimation();
                } else {
                    $(this).prop("disabled", true);
                }
            });
        }

        function displayRemoveBtn(elem, counter) {
            if (counter === 0) {
                elem[0].classList.add('d-none');
            } else {
                elem[0].classList.remove('d-none');
            }
        }

        let user_is_first_authorized_signatory = $form.find('input[name=user_is_first_authorized_signatory]');
        let contact_template = $('.contact-template');
        let first_authorized_signatories = document.querySelectorAll('input[name^="authorized_signatory[0]"]');
        let contact_fields = document.querySelectorAll('input[name^="user_"]:not(input[name=user_is_first_authorized_signatory])');

        if (user_is_first_authorized_signatory.length && contact_template !== null && first_authorized_signatories.length && contact_fields.length) {
            function uncheck_user_is_first_authorized_signatory() {
                if (user_is_first_authorized_signatory.is(':checked')) {
                    user_is_first_authorized_signatory.prop('checked', false);
                }
            }

            $(user_is_first_authorized_signatory).on('click', function () {
                if (user_is_first_authorized_signatory.is(':checked')) {
                    first_authorized_signatories.forEach(function (signatory) {
                        let signatoryName = signatory.getAttribute('name').split('[')[2].replace(']', '');
                        let signatoryValue = signatory.value;

                        let associated_contact_field = contact_template.find("input[name='user_" + signatoryName + "']");

                        if (associated_contact_field.length) {
                            associated_contact_field[0].value = signatoryValue;
                            associated_contact_field[0].parentElement.classList.add('focus');
                        }
                    });
                }
            });

            first_authorized_signatories.forEach(function (signatory) {
                signatory.addEventListener('input', uncheck_user_is_first_authorized_signatory);
            });

            contact_fields.forEach(function (contact) {
                contact.addEventListener('input', uncheck_user_is_first_authorized_signatory);
            });
        }

        let t = new FormValidation($form, {
            inputGroup: 'input-group',
            invalidClass: 'is-invalid',
            loading: '<i class="fa fa-spinner fa-spin mx-auto"></i>',
            button: form.querySelector('button[type="submit"]'),
            error: 'X',
            beforeSubmit: (res) => {

            },
            onSubmitSuccess: (res) => {
                let beforeElement = $form.find('.before-submit');
                let afterElement = $form.find('.after-submit');
                if (res.hasOwnProperty('success')) {
                    beforeElement.hide();
                    afterElement.show();
                }

                if ($form.hasClass('form-tracker')) {
                    trackData($form);
                }

                if ($form.data('sharpspring') && __ss_noform !== undefined) {
                    __ss_noform.push(['submit', null, $form.data('sharpspring')]);
                }

                if (res.hasOwnProperty('file')) {
                    if (res.file != null) {
                        window.open(res.file, '_blank');
                        location.reload();
                    }
                }

                if ($form.data('scrolltop')) {
                    window.scrollTo(0, 0);
                }

                if ($form.data('scroll-to-after')) {
                    let position = (afterElement.offset().top - afterElement.height()) - $('.nav').height();
                    window.scrollTo(0, position);
                }
            },
            onSubmitFailed: (r) => {
                if (r.hasOwnProperty('errors')) {
                    t.showErrors(r.errors);

                    if ($form.data('scroll-to-invalid')) {
                        let input = document.getElementsByClassName('is-invalid');
                        let inputHeight = $(input).height() * 2;
                        let position = ($(input).offset().top - inputHeight);
                        window.scrollTo(0, position);
                    }
                }
                if (r.hasOwnProperty('errorsbag')) {

                }
            },
        });
    }

    let contactNewsletter = document.querySelectorAll('.contact-newsletter-checkbox');
    if (contactNewsletter.length > 0) {
        contactNewsletter = contactNewsletter[0];
        contactNewsletter.querySelector('input').addEventListener('click', function () {
            if (document.querySelector('input[name="newsletter"]').checked === true) {
                document.querySelector('.newsletter-row').classList.add('active');
            } else {
                document.querySelector('.newsletter-row').classList.remove('active');
            }
        });
    }

    /****************************
     *
     * Toggle submenu
     *
     ***************************/
    let jsMobileSubmenuTrigger = document.getElementsByClassName('js-mobile-submenu-trigger');
    if (jsMobileSubmenuTrigger.length) {
        for (let i = 0; i < jsMobileSubmenuTrigger.length; i++) {
            let menuItem = jsMobileSubmenuTrigger[i];
            let subMenu = $(menuItem).closest('.menu-item').find('.submenu-item');
            menuItem.addEventListener('click', function () {
                if (this.classList.contains('is-triggered')) {
                    this.classList.remove('is-triggered');
                    subMenu.slideUp();
                } else {
                    this.classList.add('is-triggered');
                    subMenu.slideDown();
                }
            });
        }
    }

    let accordions = document.getElementsByClassName('accordion');
    if (accordions.length) {
        for (let i = 0; i < accordions.length; i++) {
            new Accordion(accordions[i]);
        }
    }

    /****************************
     *
     * Toggle FAQ
     *
     ***************************/
    let toggleItems = document.querySelectorAll('[data-action="toggle-open"]');
    if (toggleItems) {
        for (let $i = 0; $i < toggleItems.length; $i++) {
            new toggleGenerate(toggleItems[$i]);
        }
    }

    const tooltips = document.querySelectorAll('.tooltips');
    tooltipGenerator(tooltips)

    /****************************
     *
     * password eye toggle
     *
     ***************************/
    let passwordToggles = document.querySelectorAll('.password-toggle');
    if (passwordToggles.length) {
        for (let i = 0; i < passwordToggles.length; i++) {
            let passwordToggle = passwordToggles[i];
            let passwordToggleEye = passwordToggle.querySelector('.password-toggle__eye');
            let passwordInput = passwordToggle.querySelector('input[type=password]');
            let listeners = [
                'click', 'touch'
            ];
            listeners.forEach((item) => {
                passwordToggleEye.addEventListener(item, () => togglePassword(passwordInput));
            });
        }
    }

    let resultsMobile = document.querySelectorAll('.show-results-mobile');
    let menuFilter = document.querySelectorAll('.menu-filter');
    let menuFilterBody = document.querySelectorAll('.menu-filter__content');

    if (resultsMobile.length) {
        $(resultsMobile).on('click', function () {
            if ($(menuFilter).hasClass('is-open')) {
                $(menuFilter).removeClass('is-open');

                if ($(menuFilterBody).hasClass('is-open')) {
                    $(menuFilterBody).removeClass('is-open');
                }

                if ($("body").hasClass('is-locked')) {
                    $("body").removeClass('is-locked');
                }
            }
        });
    }

    /****************************
     *
     * Read more toggle
     *
     ***************************/
    let readMoreToggle = document.querySelectorAll('[data-action="toggle-read-more"]');
    for (let i = 0; i < readMoreToggle.length; i++) {
        let toggle = readMoreToggle[i];

        toggle.addEventListener('click', function () {
            let intro = $(".intro");
            let dots = $(".dots");
            let allText = $(toggle).siblings('.all')[0];
            let scroll = $('.read-more');

            if (!this.classList.contains('active')) {
                this.classList.add('active');
                this.innerHTML = "Lees minder";
                intro.each(function (key, element) {
                    element.style.display = "none";
                });

                dots.each(function (key, element) {
                    element.style.display = "none";
                });

                allText.style.display = "inline";
            } else {
                this.classList.remove('active');
                this.innerHTML = "Lees meer";
                intro.each(function (key, element) {
                    element.style.display = "inline";
                });

                dots.each(function (key, element) {
                    element.style.display = "inline";
                });

                scroll.each(function (key, element) {
                    let position = element.getBoundingClientRect().top + window.pageYOffset + -120;
                    window.scrollTo({top: position, behavior: 'smooth'});
                });

                allText.style.display = "none";
            }
        });
    }

    /****************************
     *
     * Brochure popup
     *
     ***************************/
    $('.js-brochure-trigger').on('click', brochureTriggerFunction);

    function brochureTriggerFunction() {
        var popup = $(this).next('.brochure-popup')[0];
        var popupClose = $(popup).find('.popup__close')[0];
        var popupUnderlay = $(popup).find('.popup__underlay')[0];

        togglePopUp(popup);

        function togglePopupHandler() {
            togglePopUp(popup);
            this.removeEventListener('click', togglePopupHandler);
        }

        popupClose.addEventListener('click', togglePopupHandler);

        popupUnderlay.addEventListener('click', togglePopupHandler);
    }

    function togglePopUp(popup) {
        if (!popup.classList.contains('active')) {
            document.body.classList.add('is-locked');
            popup.classList.add('active');
        } else {
            document.body.classList.remove('is-locked');
            popup.classList.remove('active');
        }
    }

    /****************************
     *
     * Comparison
     *
     ***************************/
    const comparisonTiles = () => {
        let comparisonText = $('.comparison__text');
        comparisonText.height('');
        let comparisonTextMaxHeight = Math.max.apply(
            Math, comparisonText.map(function () {
                return $(this).height();
            }).get());
        comparisonText.height(comparisonTextMaxHeight);

        let comparisonCosts = $('.comparison__costs');
        comparisonCosts.height('');
        let comparisonCostsMaxHeight = Math.max.apply(
            Math, comparisonCosts.map(function () {
                return $(this).height();
            }).get());
        comparisonCosts.height(comparisonCostsMaxHeight);

        let comparisonInfo = $('.comparison__info');
        comparisonInfo.height('');
        let comparisonInfoMaxHeight = Math.max.apply(
            Math, comparisonInfo.map(function () {
                return $(this).height();
            }).get());
        comparisonInfo.height(comparisonInfoMaxHeight);

        // Make sure the rows have the same height
        comparisonInfo.each(function () {
            $(this).find('.col-12').each(function () {
                $(this).find(`small[data-name]`).each(function () {
                    $(this).parent().height('');

                    let maxColHeight = Math.max.apply(
                        Math, $(`[data-name='${$(this).data('name')}']`).map(function () {
                            return $(this).parent().height();
                        }).get()
                    );

                    $(this).parent().height(maxColHeight);
                });
            });
        });

        let comparisonRun = $('.comparison__run');
        comparisonRun.height('');
        let comparisonRunMaxHeight = Math.max.apply(
            Math, comparisonRun.map(function () {
                return $(this).height();
            }).get());
        comparisonRun.height(comparisonRunMaxHeight);

        let comparisonRequirements = $('.comparison__requirements');
        comparisonRequirements.height('');
        let comparisonRequirementsMaxHeight = Math.max.apply(
            Math, comparisonRequirements.map(function () {
                return $(this).height();
            }).get());
        comparisonRequirements.height(comparisonRequirementsMaxHeight);
    }

    comparisonTiles();

    $(window).on('resize', () => {
        comparisonTiles();
    });

    /****************************
     *
     * Organization categories text
     *
     ***************************/
    const grid = document.querySelector('.grid');
    if (grid) {
        new Colcade(grid, {
            columns: '.grid-col',
            items: '.grid-item'
        });
    }

    /****************************
     *
     * News overview LazyLoad
     *
     ***************************/
    let ajaxContainer = document.getElementById('ajax-results');

    new FormFilterClass({
        form: document.getElementById('lazy-search'),
        container: ajaxContainer,
        lazyload: 400,
        loader: document.getElementById('append_loader'),
    });

    /****************************
     *
     * Analytics Tracker
     *
     ***************************/
    let trackers = document.getElementsByClassName('tracker');
    if (trackers.length) {
        for (let i = 0; i < trackers.length; i++) {
            const $tracker = $(trackers[i]);
            trackData($tracker)
            const data = $tracker.data('trackerObject');
            const trackerAction = $tracker.data('trackerAction');
            const trackerType = $tracker.data('trackerType') ?? 'event';

            if (trackerType) {
                tracker.trackData(trackerType, trackerAction, data);
            } else {
                tracker.trackPageview(data);
            }
        }
    }

    /****************************
     *
     * External Link Tracker
     *
     ***************************/
    let externalTrackerLinks = document.querySelectorAll('[data-track]');
    if (externalTrackerLinks.length) {
        externalTrackerLinks.forEach(function (link) {
            link.addEventListener('click', function () {
                let externalTrackerLinkUrl = link.getAttribute('data-track');
                if (externalTrackerLinkUrl.length) {
                    $.post(externalTrackerLinkUrl);
                }
            });
        });
    }

    /****************************
     *
     * Organization Detail Category Selector
     *
     ***************************/
    let organizationCategoryButtons = document.querySelectorAll('[data-organization-category-button]');
    if (organizationCategoryButtons.length) {
        organizationCategoryButtons.forEach(function (button) {
            button.addEventListener('click', function () {
                let organizationCategoryOverviews = document.querySelectorAll('[data-organization-category-overview]');
                if (organizationCategoryOverviews.length) {
                    organizationCategoryOverviews.forEach(function (overview) {
                        if (overview.getAttribute('data-organization-category-overview') === button.getAttribute('data-organization-category-button')) {
                            overview.classList.add('d-block');
                            overview.classList.remove('d-none');
                        } else {
                            overview.classList.remove('d-block');
                            overview.classList.add('d-none');
                        }
                    });
                }
            });
        });
    }

    $(document).ready(function () {
        $('.ccm__cheading__close').html('—');
    })

    /****************************
     *
     * Show contact information toggle
     *
     ***************************/
    let contactInformationToggle = document.querySelectorAll('[data-action="toggle-contact-information"]');

    for (let i = 0; i < contactInformationToggle.length; i++) {
        let toggle = contactInformationToggle[i];

        toggle.addEventListener('click', function () {
            let placeholder = this.parentElement.querySelector('.contact-information-placeholder');
            let contactInformation = this.parentElement.querySelector('.contact-information');

            if (!this.classList.contains('active')) {
                this.classList.add('active');
                placeholder.style.display = "none";
                contactInformation.style.display = "inline";
                if (placeholder.classList.contains('trackable')) {
                    trackData($(placeholder))
                }
            }
        });
    }

    const programDiscountPopup = document.querySelector('.js-program-discount-popup');
    const programDiscountPopupTrigger = document.querySelector('.js-discount-popup-trigger');
    new popupGenerate(programDiscountPopup, programDiscountPopupTrigger);
})



