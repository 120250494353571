import React from "react";
import {Arrow, Puff} from "./Icons";

let classNames = require('classnames');

export default function Button(props) {
    const {title, arrow, disabled, type, isLoading, color, onClick} = props;

    let classObject = {
        'btn__link': true,
        'btn__arrow': arrow || isLoading,
        'disabled': disabled,
        'btn--isloading': isLoading
    };
    classObject['btn-solid--' + color] = true;

    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames(classObject)}
            onClick={onClick}
        >
            {title} {arrow && !isLoading && <Arrow/>} {isLoading && <Puff/>}
        </button>
    )
}
