import React from "react";
import {Compare} from "../Helpers/Icons";
import AddComparison from "./AddComparison";

let classNames = require('classnames');

export default function AddToComparisonHorizontal(props) {

    const {id, type} = props;

    return (
        <AddComparison id={id} type={type}>
            {(active) => (
                <div
                    className={classNames('program-compare d-none d-tablet-block', {'is-active': active})}>
                    <Compare/>
                    <span className="program-compare__content font-weight-bold">
                        Vergelijk
                    </span>
                </div>
            )}
        </AddComparison>
    )
}
