import React, {useContext, useEffect, useState} from 'react';
import LoginApp from "../Modules/login/Containers/login-app";
import Popup from "../../Popup/popup";
import {ComparisonContext} from "../Context/userProvider";

const _find = require('lodash/find');
const _size = require('lodash/size');
const axios = require('axios');

export default function AddComparisons({id, children, type}) {
    const IsUser = window.user.length !== 0;
    const [showPopup, setShowPopup] = useState(false);
    const [comparisons, setComparisons] = useContext(ComparisonContext);

    const exist = () => {
        let index = _find(comparisons[type], (e) => {
            return e === Number(id);
        });

        return !!index;
    };

    const checkValidComparison = () => {
        return _size(comparisons[type]) > 1
    }

    const [active, setActive] = useState(exist());
    const [validComparison, setValidComparison] = useState(checkValidComparison);

    useEffect(() => {
        showPopup ? document.body.classList.add('is-locked') : document.body.classList.remove('is-locked');
    }, [showPopup]);

    useEffect(() => {
        setActive(exist());
    }, [comparisons]);

    useEffect(() => {
        setValidComparison(checkValidComparison());
    }, [comparisons]);


    const onRemoveComparison = () => {
        setActive(false);
        setValidComparison(checkValidComparison());
        axios
            .post(window.comparisons_delete, {
                'type': type,
                id: id
            })
            .then(response => setComparisons(response.data))
            .catch(() => setActive(true), setValidComparison(checkValidComparison()));
    };

    const onAddComparison = () => {
        setActive(true);
        setValidComparison(checkValidComparison());
        axios
            .post(window.comparisons_add, {
                'type': type,
                id: id
            })
            .then(response => setComparisons(response.data))
            .catch(() => setActive(true), setValidComparison(checkValidComparison()));
    };

    const toggleComparison = () => {
        if (IsUser) {
            active ? onRemoveComparison() : onAddComparison();
        } else {
            setShowPopup(true);
        }
    };

    return (
        <React.Fragment>
            <div onClick={(() => toggleComparison())}>
                {children && typeof children === 'function'
                    ? children(active, validComparison)
                    : children}
            </div>
            {showPopup && <Popup active={showPopup} setActive={setShowPopup}>
                <div>
                    <LoginApp view="login-comparison" addParam={{'insert': id}}/>
                </div>
            </Popup>}
        </React.Fragment>
    )
}
