import anime from 'animejs';

export default class {
    constructor() {
        this.open = false;
        this.isAnimate = false;
        this.menu = document.querySelector('.menu');
        this.html = document.querySelector('html');
        this.underlay = document.querySelector('.menu-underlay');
        this.nav = document.querySelector('nav');
        this.mobileFilter = document.querySelector('.menu-filter');

        if (this.menu) {
            this.hamburger = document.querySelector('.hamburgers');
            this.init();
        }
    }

    init() {
        this.hamburger.addEventListener('click', () => {
            this.open ? this.closeMenu() : this.openMenu()
        });
        this.menu.classList.add('init');
        this.underlay.addEventListener('click', () => this.fastCloseMenu());

    }

    fastCloseMenu() {
        this.open = false;
        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        this.underlay.classList.remove('is-active');
        this.showFilters();

        setTimeout(() => {
            this.html.classList.remove('is-locked');
        }, 500);
    }

    // Menu animation
    closeMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.startAnimate();
        this.open = false;

        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        setTimeout(() => this.endAnimate(), 500);
        this.html.classList.remove('is-locked');
        this.underlay.classList.remove('is-active');
        this.showFilters();
    }

    openMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.html.classList.add('is-locked');
        this.startAnimate();
        this.open = true;
        this.hamburger.classList.add('open');
        this.menu.classList.add('open');
        this.underlay.classList.add('is-active');
        this.hideFilters();

        setTimeout(() => {
            this.endAnimate();
        }, 110)
    }

    startAnimate() {
        this.isAnimate = true
    }

    endAnimate() {
        this.isAnimate = false;
    }

    hideFilters() {
        if (this.mobileFilter) {
            this.mobileFilter.classList.add('is-hidden');
        }
    }

    showFilters() {
        if (this.mobileFilter) {
            if (this.mobileFilter.classList.contains('is-hidden')) {
                this.mobileFilter.classList.remove('is-hidden');
            }
        }
    }
}
