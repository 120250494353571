import FormText from "../Components/inputs/text";
import React, {useState} from 'react';
import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Alert from "../../../Components/Alert";
import Popup from "../../../../Popup/popup";
import FormSelect from "../Components/inputs/select";

const axios = require('axios');

export default function AccountEdit(props) {
    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [user, setUser] = useState(window.user);
    const [currentUser, setCurrentUser] = useState(window.user);
    const labels = window.translations;
    const selectValues = window.selectValues;

    const handleChange = (event) => setUser({...user, ...{[event.target.name]: event.target.value}});

    const showButton = Object.keys(user).length >= 1;

    const onSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(null);
        setErrorBag({});
        setLoading(true);

        axios.post(window.account_edit, user).then(function (response) {
            if (response.data) {
                setCurrentUser(user);
                setSuccessMessage(response.data.message);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;
                setErrorBag(data.errors);
            }
        });
    };

    return (
        <React.Fragment>

            <div className="px-20 py-10 bg-white">
                <div className="text--h4 text-primary-60 my-10">Je gegevens</div>

                <div className="my-30">
                    {Object.keys(currentUser).map(function (key) {
                        return <div className="row" key={key}>
                            <div className="col-6">
                                <div className="text-truncate">
                                    <small className="text-primary font-weight-bold mr-5">
                                        {labels[key]}
                                    </small>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="text-truncate">
                                    <small className="text-primary">
                                        {key.includes('_id') ? (selectValues[key][currentUser[key]] ?? '') : currentUser[key]}
                                    </small>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>

            {showPopup && <Popup active={showPopup} setActive={setShowPopup}>

                <form onSubmit={onSubmit}>
                    <div className="dashboard-tile dashboard-tile--white px-30 pt-10 pb-30 bg-white">

                        <h1 className="text--h1 font-weight-bold text-primary-60 my-20">Gegevens wijzigen</h1>

                        <ErrorBag errors={errorBag}/>
                        <Alert message={successMessage} color="green" isActive={!!successMessage}/>

                        <div className="my-20">
                            {Object.keys(user).map(function (key) {
                                return <div className="row align-items-center my-10" key={key}>
                                    <div className="col-6">
                                        <div className="text-truncate">
                                            <small className="text-primary font-weight-bold mr-5">
                                                {labels[key]}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        {key.includes('_id')
                                            ?
                                            <FormSelect
                                                value={selectValues[key][currentUser[key]] ?? ''}
                                                name={key}
                                                onChange={handleChange}
                                                values={selectValues[key] ?? []}
                                            />
                                            :
                                            <FormText
                                                value={user[key] ?? ''}
                                                name={key}
                                                type={key == 'email' ? 'email' : 'text'}
                                                onChange={handleChange}
                                            />
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="link-holder mt-n20 d-flex justify-content-center">
                        <Button
                            title="Wijzigen"
                            color="yellow"
                            disabled={!showButton}
                            type="submit"
                            arrow={true}
                            isLoading={loading}
                        />
                    </div>
                </form>

            </Popup>}


            <div className="link-holder mt-n20 mx-30 d-flex justify-content-end">
                <Button
                    title="Gegevens wijzigen"
                    color="yellow"
                    disabled={!showButton}
                    type="submit"
                    arrow={true}
                    onClick={() => {
                        setShowPopup(true)
                    }}
                />
            </div>

        </React.Fragment>
    )
}
