const $window = $(window);

const getSize = () => {
    let w = $window.width();
    let size = 'small';
    if (w > 768) {
        size = 'medium';
    }

    if (w > 1200) {
        size = 'large';
    }
    return size;
};

const ReplaceImage = (element, threshold, data) => {
    InView(element, threshold, function () {
        var url = element.data(data);

        var img = new Image();
        img.src = url;
        img.onload = function () {
            element.attr('src', url);
            element.addClass('active');
        };
    });
};

const ReplaceBG = (element, threshold, data) => {
    setTimeout(() => {
        InView(element, threshold, function () {
            var $this = $(this);
            var url = $this.data(data);

            var img = new Image();
            img.src = url;
            img.onload = function () {
                $this.css('background-image', "url('" + url + "')");
                $this.addClass('active');
            };
        });
    }, 1000)
};


const InView = (image, th = 0, callback) => {
    let $w = $(window),
        loaded;

    const unveil = () => {
        if (loaded) {
            return false;
        }
        let inview = function () {
            var $e = $(image);
            var wt = $w.scrollTop(),
                wb = wt + $w.height(),
                et = $e.offset().top,
                eb = et + $e.height();
            return eb >= wt - th && et <= wb + th;
        }();
        if (inview) {
            loaded = inview;
            if (typeof callback === "function") callback.call(image);
        }
    };
    $w.on('scroll', unveil);

    unveil();
};


export class LazyImage {
    constructor(element, threshold = 0) {
        this.element = element;
        this.threshold = threshold;
        this.size = getSize();
        this.init();
    }

    init() {
        ReplaceImage(this.element, this.threshold, 'img-' + this.size);
    }

}


export class LazyImages {
    constructor(element, threshold = 0) {
        this.element = element;
        this.threshold = threshold;
        this.size = getSize();
        this.init();
    }

    init() {
        let _this = this;
        let _size = this.size;
        this.element.find('[data-img-small][data-img-medium][data-img-large]').each(function () {
            ReplaceImage($(this), _this.threshold, 'img-' + _size);
        });
        //
        this.element.find('[data-bg-small][data-bg-medium][data-bg-large]').each(function () {
            ReplaceBG($(this), _this.threshold, 'bg-' + _size);
        });

    }
}


