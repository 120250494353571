import React from "react";

let _isEmpty = require('lodash/isEmpty');

export default function Alert(props) {
    const {message, isActive, color, textColor} = props;

    if (!isActive) {
        return <React.Fragment/>
    }

    let border = 'border-' + (!_isEmpty(color) ? color : 'primary');
    let text_color = 'text-' + (!_isEmpty(textColor) ? textColor : 'primary');

    return (
        <div className={"px-20 py-10 bg-white " + border}>
            <div className={"my-10 " + text_color}>
                <small>
                    {message}
                </small>
            </div>
        </div>
    )
}
