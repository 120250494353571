import React from "react";
import {Arrow, Puff} from "./Icons";

let classNames = require('classnames');

export default function Link(props) {
    const {title, arrow, type, isLoading, onClick, color, arrowRight = true} = props;

    let classObject = {
        'btn__link': true,
        'btn__arrow': arrow || isLoading,
        'btn--isloading': isLoading,
        'btn__arrow--revert': !arrowRight
    };

    classObject['btn-color--' + color] = true;

    return (
        <a
            onClick={onClick}
            type={type}
            className={classNames(classObject)}>
            {title} {arrow && !isLoading && <Arrow/>} {isLoading && <Puff/>}
        </a>
    )
}
