import React, {useState} from 'react';
import {InputWrapper} from "./Wrapper";

export default function FormText(props) {

    const [focus, setFocus] = useState(false);
    const {name, title, type, placeholder, onChange, value, className, readOnly, color, border} = props;

    return (
        <InputWrapper name={name} title={title} isFocused={focus || value.length} color={color} border={border}>
            <input
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={className}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                readOnly={readOnly}
            />
        </InputWrapper>
    )
}
