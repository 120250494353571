import React from 'react';

export const Hearth = () => {
    return (
        <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
             className="svg-blade">
            <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
               strokeLinejoin="round">
                <g id="Group-661-Copy" transform="translate(1.000000, 1.000000)" strokeWidth="2" className="stroke-line">
                    <path
                        d="M18.0902505,1.70249517 C16.1236281,-0.415719733 12.9354205,-0.415719733 10.9696613,1.70249517 L9.7826087,2.98161045 L8.59555606,1.70249517 C6.62893358,-0.415719733 3.44072603,-0.415719733 1.47496686,1.70249517 C-0.491655619,3.82164034 -0.491655619,7.25711141 1.47496686,9.37532631 L9.7826087,18.3272727 L18.0902505,9.37532631 C20.056873,7.25711141 20.056873,3.82071007 18.0902505,1.70249517 Z"
                        id="Stroke-5492"/>
                </g>
            </g>
        </svg>
    );
};

export const Compare = () => {
    return (
        <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" className="svg-blade svg-compare"
             viewBox="0 0 512 512">
            <g stroke="#009FE3" strokeWidth="25" className="stroke-line">
                <path className="st0" fill="none"
                      d="M378.8,162.8L266.2,55.4v61.4c-107.7,0-133,77.2-133,153.5c31.1-39.8,58.6-61.4,133-61.4v61.4L378.8,162.8z"/>
                <path className="st0" fill="none"
                      d="M115.2,360.4l112.6,107.4v-61.4c107.7,0,133-77.2,133-153.5c-31.1,39.8-58.6,61.4-133,61.4v-61.4L115.2,360.4z"
                />
            </g>
        </svg>
    );
};

export const Arrow = () => {
    return (
        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg"
             className="svg-blade">
            <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="svg-arrow" id="Group-160-Copy" stroke="#1E386C" strokeWidth="2">
                    <path
                        d="M11.0014592,1 C8.23950899,1 5.73871714,2.11864249 3.92860702,3.92803231 C2.11919593,5.7367234 1,8.23562106 1,10.9970815 C1,13.7587961 2.11973256,16.2595693 3.92982606,18.0699029 C5.73979007,19.880107 8.24005884,21 11.0014592,21 C13.7625681,21 16.2619382,19.8803182 18.0711434,18.0703929 C19.8808184,16.2599976 21,13.7590144 21,10.9970815 C21,8.2354028 19.8813548,5.73629527 18.0723621,3.92754256 C16.2630109,2.11843148 13.7631179,1 11.0014592,1 Z"
                        id="Stroke-3793"/>
                    <g id="Group-4" transform="translate(6.000000, 7.000000)" strokeLinecap="round"
                       strokeLinejoin="round">
                        <polyline id="Stroke-3794"
                                  points="5.61441667 7.77156117e-14 9.56858333 3.95416667 5.61441667 7.90833333"/>
                        <line x1="-3.01092484e-13" y1="3.95391667" x2="9.56833333" y2="3.95391667"
                              id="Stroke-3795"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};
