import React from 'react';
import ReactDOM from 'react-dom';
import LoginApp from './Modules/login/Containers/login-app';
import FavApp from "./Modules/detailFavorites/Containers/fav-app";
import AccountEdit from "./Modules/login/Containers/account-edit";
import AccountDelete from "./Modules/login/Containers/account-delete";
import AccountPassword from "./Modules/login/Containers/account-password";
import ComparisonsOverview from "./Modules/login/Containers/comparisons-overview";
import CompareApp from "./Modules/detailComparisons/Containers/compare-app";
import AddProgram from "./Modules/addProgram/Containers/AddProgram";
import AddObject from "./Modules/addProgram/Containers/AddObject";

let root = document.getElementById('login-container');

if (root) {
    ReactDOM.render(<LoginApp{...(root.dataset)}/>, root);
}

let rootSaveObject = document.getElementsByClassName('add-object');
if (rootSaveObject.length) {
    for (let i = 0; i < rootSaveObject.length; i++) {
        ReactDOM.render(<AddObject{...(rootSaveObject[i].dataset)}/>, rootSaveObject[i]);
    }
}

let rootfav = document.getElementById('add-fav');
if (rootfav) {
    ReactDOM.render(<FavApp{...(rootfav.dataset)}/>, rootfav);
}

let rootcompare = document.getElementById('add-compare');
if (rootcompare) {
    ReactDOM.render(<CompareApp{...(rootcompare.dataset)}/>, rootcompare);
}

let rootfavMobile = document.getElementById('add-fav-mobile');
if (rootfavMobile) {
    ReactDOM.render(<FavApp{...(rootfavMobile.dataset)}/>, rootfavMobile);
}

let rootEditAccount = document.getElementById('account-edit-container');

if (rootEditAccount) {
    ReactDOM.render(<AccountEdit{...(rootEditAccount.dataset)}/>, rootEditAccount);
}

let rootDeleteAccount = document.getElementById('account-delete-container');

if (rootDeleteAccount) {
    ReactDOM.render(<AccountDelete{...(rootDeleteAccount.dataset)}/>, rootDeleteAccount);
}

let rootAccountPassword = document.getElementById('account-password-container');

if (rootAccountPassword) {
    ReactDOM.render(<AccountPassword{...(rootAccountPassword.dataset)}/>, rootAccountPassword);
}

let rootComparisonsOverview = document.getElementById('comparisons-overview-container');

if (rootComparisonsOverview) {
    ReactDOM.render(<ComparisonsOverview{...(rootComparisonsOverview.dataset)}/>, rootComparisonsOverview);
}

let rootAddProgram = document.getElementsByClassName('add-program');

if (rootAddProgram.length) {
    for (let i = 0; i < rootAddProgram.length; i++) {
        ReactDOM.render(<AddProgram{...(rootAddProgram[i].dataset)}/>, rootAddProgram[i]);
    }
}
