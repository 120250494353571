export class FormFilterClass {
    constructor(options) {
        this.conf = FormFilterClass.mergeSettings(options);

        this.loading = false;

        this.remain = true;
        this.window = $(window);
        this.conf.currentpage = this.conf.startpage;

        this.init();
    }

    /**
     * Overrides default settings with custom ones.
     * @param {Object} options - Optional settings object.
     * @returns {Object} - Custom FormFilterClass settings.
     */
    static mergeSettings(options) {
        const settings = {
            form: false,
            container: false,
            lazyload: false,
            startpage: 1,
            isAjax: true,
            onInit: () => {
            },
            onLazyload: () => {
            },
            onChange: () => {
            },

            /**
             *  Null or Function
             */
            processResponse: null
        };

        const userSttings = options;
        for (const attrname in userSttings) {
            settings[attrname] = userSttings[attrname];
        }

        return settings;
    }


    fetch(append, callback = null) {
        let page = this.conf.currentpage;
        let _this = this;
        let $f = $(this.conf.form);
        let data = $f.serializeArray();
        let result = null;

        if (page && page !== 1) {
            data.push({name: 'page', value: page});
        }

        $.get(this.conf.form.getAttribute('action'), data)
            .then(function (r) {
                result = r;
                let $r = r.dom;
                _this.remain = r.remain;

                if (_this.conf.processResponse) {
                    _this.conf.processResponse(_this.conf.container, $r, append);
                } else {
                    if (append) {
                        let node = document.createRange().createContextualFragment($r);
                        _this.conf.container.appendChild(node);
                    } else {
                        if (!$r) {
                            _this.conf.container.innerHTML = '';
                        } else {
                            _this.conf.container.innerHTML = $r;
                        }
                    }
                }

                _this.finish_loading();

                if (callback) {
                    callback(result)
                }
            });

    }

    start_loading() {
        this.loading = true;
        if (this.conf.loader) {
            this.conf.loader.style.display = 'block';
        }
    }

    finish_loading() {
        this.loading = false;
        if (this.conf.loader) {
            this.conf.loader.style.display = 'none';
        }
    }

    updatePage() {
        this.conf.currentpage++;
    }


    lazyloader() {
        const rect = this.conf.container.getBoundingClientRect();
        if (this.loading || !this.remain) {
            return;
        }
        if ((this.window.height() + this.window.scrollTop()) > (rect.height - this.conf.lazyload)) {
            this.start_loading();
            this.updatePage();
            this.fetch(true, () => {
                this.conf.onLazyload.call(this);
                this.lazyloader();
            });
        }
    }

    change() {
        this.conf.container.innerHTML = '';
        this.start_loading();
        this.conf.currentpage = 1;
        this.fetch(false, () => this.conf.onChange.call(this));
    }

    init() {

        if (this.conf.form && this.conf.container) {
            if (this.conf.loader) {
                this.conf.loader.style.display = 'none';
            }

            let $f = $(this.conf.form);

            $f.on('change', () => {
                if (this.conf.isAjax) {
                    this.change();
                } else {
                    $f.submit();
                }
            })
            ;

            if (this.conf.lazyload) {
                this.window.on('scroll', this.lazyloader.bind(this));
            }

            this.window.scroll();
            this.conf.onInit.call(this);
        }
    }
}
