import tooltipGenerator from "./tooltip";

export default class {
    constructor(element) {
        this.open = element;
        this.body = $(this.open.nextElementSibling);
        this.animate = false;
        this.init();
    }

    toggleItem() {
        if (this.animate) {
            return false;
        }

        this.animate = true;


        if (this.open.classList.contains('toggle-open--is-active')) {
            this.body.slideUp();
            this.open.classList.remove('toggle-open--is-active', 'toggle__head--open');
        } else {
            this.body.slideDown();
            this.open.classList.add('toggle-open--is-active', 'toggle__head--open');
            // todo: find a way to remove tooltip (and its event-listener) when hidden
            tooltipGenerator(this.body.find('.tooltips'))
        }

        setTimeout(() => {
            this.animate = false;
        }, 300);
    }

    init() {
        this.open.addEventListener('click', (e) => this.toggleItem());

    }
}
