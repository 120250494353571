import $ from 'jquery';

const track = function (endpoint, data) {
    $.ajax({
        url: endpoint,
        method: 'POST',
        data
    });
};

export default class Tracker {
    constructor(endpoint) {
        this.endpoint = endpoint;

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }

    trackData(type, action, data) {
        track(this.endpoint, {...data, type, action});
    }

}
