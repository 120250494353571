import React from 'react';

let classNames = require('classnames');

export class InputWrapper extends React.Component {
    render() {
        const {children, name, title, isFocused, color, border} = this.props;

        return (
            <div className={`input-group input-group__text ${border}`}>
                <div className={classNames('input-group__body', 'animate', {'focus': isFocused})}>
                    <label htmlFor={name} className={`input-label ${color}`}>
                        {title}
                    </label>
                    {children}
                </div>
            </div>
        )
    }
}



