import React from "react";
import AddFavorites from "./AddFavorite";
import {Hearth} from "../Helpers/Icons";

let classNames = require('classnames');

export default function AddToFavoriteHorizontal(props) {

    const {id, type} = props;

    return (
        <AddFavorites id={id} type={type}>
            {active => (
                <div className={classNames('program-save', {'is-active': active})}>
                    <Hearth/>
                    <span className="program-save__content font-weight-bold">
                        Bewaar
                    </span>
                </div>
            )}
        </AddFavorites>
    )
}
