import React, {useState} from 'react';
import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Alert from "../../../Components/Alert";
import Link from "../../../Components/Link";

const axios = require('axios');

export default function AccountDelete(props) {
    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [user, setUser] = useState(window.user);
    const labels = window.translations;
    const showButton = Object.keys(user).length >= 1;

    const onSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(null);
        setErrorBag({});
        setLoading(true);

        axios.post(window.account_delete, user).then(function (response) {
            if (response.data) {
                setSuccessMessage(response.data.message);
                window.location.href = response.data.url;
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;
                setErrorBag(data.errors);
            }
        });
    };

    return (
        <React.Fragment>

            <form onSubmit={onSubmit}>
                <div className="dashboard-tile dashboard-tile--white px-30 pt-10 pb-30 bg-white">

                    {window.redirectUrl &&
                        <div className="ml-n15 my-20">
                            <Link
                                onClick={() => window.location = window.redirectUrl}
                                title="terug"
                                arrow={true}
                                arrowRight={false}
                                color="primary"
                            />
                        </div>
                    }

                    <h1 className="text--h1 font-weight-bold text-primary-60 my-20">
                        Weet u zeker dat u uw account wilt verwijderen?
                    </h1>

                    <ErrorBag errors={errorBag}/>
                    <Alert message={successMessage} color="green" isActive={!!successMessage}/>

                    <div className="my-20">
                        {Object.keys(user).map(function (key) {
                            return <div className="row align-items-center" key={key}>
                                <div className="col-6">
                                    <div className="text-truncate">
                                        <small className="text-primary font-weight-bold mr-5">
                                            {labels[key]}
                                        </small>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="text-truncate">
                                        <small>{user[key]}</small>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="link-holder mt-n20 d-flex justify-content-center">
                    <Button
                        title="Verwijder account"
                        color="yellow"
                        disabled={!showButton}
                        type="submit"
                        arrow={true}
                        isLoading={loading}
                    />
                </div>
            </form>
        </React.Fragment>
    )
}
