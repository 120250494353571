import FormText from "../Components/inputs/text";
import React, {useState} from 'react';
import FormPassword from "../Components/inputs/password";
import FormCheckbox from "../Components/inputs/checkbox";

import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Alert from "../../../Components/Alert";
import FormSelect from "../Components/inputs/select";

const axios = require('axios');

export default function Register(props) {
    const {handleChange, handleClick, changeStatus} = props;
    const {
        email,
        password,
        firstname,
        lastname,
        terms,
        addParam,
        newsletter,
        program_area_1,
        program_area_2,
        zipcode
    } = props.values;
    const showButton = password.length >= 1 && email.length >= 1 && firstname.length >= 1 && terms === true;
    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);

    if (document.querySelector('.login-column')) {
        document.querySelector('.login-column').classList.remove('col-6');
        document.querySelector('.login-column').classList.add('col-12');
    }

    if (document.querySelector('.popup__content')) {
        document.querySelector('.popup__content').classList.add('register');
    }

    const showNewsletterFields = () => {
        if (document.querySelector('input[name="newsletter"]').checked === false) {
            document.querySelector('.newsletter-row').classList.add('active');
        } else {
            document.querySelector('.newsletter-row').classList.remove('active');

            document.querySelector("#zipcode").value = ""; // TODO make fields empty when unchecked
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setErrorBag({});
        setLoading(true);
        axios.post(window.register,
            {
                ...{
                    email: email,
                    password: password,
                    firstname: firstname,
                    lastname: lastname,
                    terms: terms,
                    newsletter: newsletter,
                    program_area_1: program_area_1,
                    program_area_2: program_area_2,
                    zipcode: zipcode,
                }, ...addParam
            })
            .then(function (response) {
                if (response.data) {
                    if (response.data.hasOwnProperty('message')) {
                        setSuccessMessage(response.data.message);
                        return;
                    }
                    window.location.reload();
                }
            }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;
                setErrorBag(data.errors);
            }
        });
    };

    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <div className="px-30 pt-10 pb-30 bg-primary-60 popup-content-wrapper">
                    <div className="my-20">
                        <h1 className="text--h1 text-white font-weight-bold">
                            Nog een vinkje en een klik en je account met het e-mailadres {email} is klaar voor
                            gebruik.
                        </h1>
                    </div>

                    <ErrorBag errors={errorBag}/>
                    <Alert message={successMessage} color="green" isActive={!!successMessage}/>

                    <div className="my-20">
                        <div className="row">
                            <div className="col-12 col-tablet-6">
                                <div className="my-10">
                                    <FormText
                                        onChange={handleChange}
                                        title="Voornaam"
                                        value={firstname}
                                        name="firstname"
                                        color={firstname.length < 1 ? 'text-red-alt' : ''}
                                        border={firstname.length < 1 ? 'border-red-alt' : ''}
                                    />
                                </div>

                                <div className="my-10">
                                    <FormText
                                        onChange={handleChange}
                                        title="Achternaam"
                                        value={lastname}
                                        name="lastname"
                                    />
                                </div>

                                <div className="my-10">
                                    <FormText
                                        onChange={handleChange}
                                        title="E-mail"
                                        value={email}
                                        name="email"
                                        type="email"
                                        color={email.length < 1 ? 'text-red-alt' : ''}
                                        border={email.length < 1 ? 'border-red-alt' : ''}
                                    />
                                </div>

                                <div className="my-10">
                                    <FormPassword
                                        onChange={handleChange}
                                        title="Wachtwoord"
                                        value={password}
                                        name="password"
                                        color={password.length < 1 ? 'text-red-alt' : ''}
                                        border={password.length < 1 ? 'border-red-alt' : ''}
                                    />
                                </div>

                                <div className="my-10">
                                    <FormCheckbox
                                        onChange={handleChange}
                                        label="Ja, ik wil mij aanmelden voor de nieuwsbrief"
                                        value={newsletter}
                                        name="newsletter"
                                        onClick={() => handleClick('newsletter', !newsletter, showNewsletterFields())}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-tablet-6">
                                <div className="my-10">
                                    <div className="px-20 py-10 bg-white border-primary login-terms">
                                        <div className="my-10 ">
                                            <small dangerouslySetInnerHTML={{__html: window.terms}}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="my-10">
                                    <FormCheckbox
                                        onChange={handleChange}
                                        label="Ja, ik heb het privacy statement doorgelezen"
                                        value={terms}
                                        name="terms"
                                        onClick={() => handleClick('terms', !terms)}
                                        color={!terms ? 'text-red-alt' : ''}
                                        border={!terms ? 'border-red-alt px-5' : 'px-5 mx-1 pt-1'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row row--10 newsletter-row">
                            <div className="col-12 col-tablet-4">
                                <div className="my-5">
                                    <FormSelect
                                        onChange={handleChange}
                                        title="Vakgebied 1"
                                        values={window.program_areas}
                                        value={program_area_1}
                                        name="program_area_1"
                                        onClick={() => handleClick('program_area_1', !program_area_1)}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-tablet-4">
                                <div className="my-5">
                                    <FormSelect
                                        onChange={handleChange}
                                        title="Vakgebied 2"
                                        values={window.program_areas}
                                        value={program_area_2}
                                        name="program_area_2"
                                        onClick={() => handleClick('program_area_2', !program_area_2)}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-tablet-4">
                                <div className="my-5">
                                    <FormText
                                        onChange={handleChange}
                                        title="Postcode"
                                        value={zipcode}
                                        name="zipcode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="link-holder text-center mt-n20">
                    <div className="bg-white d-inline-block">
                        <Button
                            title="Registreren afronden"
                            color="yellow"
                            disabled={!showButton}
                            type="submit"
                            arrow={true}
                            isLoading={loading}
                        />
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}
