import FormText from "../Components/inputs/text";
import React, {useState} from 'react';
import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Alert from "../../../Components/Alert";

const axios = require('axios');

export default function PasswordReset(props) {
    const {handleChange, title, changeStatus} = props;
    const {email, password} = props.values;
    const showButton = email.length;
    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);

    const onSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(null);
        setErrorBag({});
        setLoading(true);
        axios.post(window.password_reset, {
            email: email,
        }).then(function (response) {
            if (response.data) {
                if (response.data.hasOwnProperty('url')) {
                    window.location.href = response.data.url;
                    return;
                }
                if (response.data.hasOwnProperty('message')) {
                    setSuccessMessage(response.data.message);
                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;
                setErrorBag(data.errors);
            }
        });
    };
    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <div className="px-30 pt-10 pb-30 bg-primary-60">
                    <div className="my-20">
                        <div className="text--h1 text-white font-weight-bold">
                            {title}
                        </div>
                    </div>

                    <ErrorBag errors={errorBag}/>
                    <Alert message={successMessage} color="green" isActive={!!successMessage}/>

                    <div className="my-20">
                        <div className="my-10">
                            <FormText
                                onChange={handleChange}
                                title="E-mail"
                                value={email}
                                name="email"
                                type="email"
                            />
                        </div>
                    </div>

                </div>
                <div className="link-holder text-center mt-n20">
                    <div className="bg-white d-inline-block">
                        <Button
                            title="Verstuur"
                            color="yellow"
                            disabled={!showButton}
                            type="submit"
                            arrow={true}
                            isLoading={loading}
                        />
                    </div>
                </div>
            </form>
        </React.Fragment>


    )
}
