var _map = require('lodash/map');
var _empty = require('lodash/isEmpty');


export default class inputAnimation {


    constructor(element) {
        this.config = {};
        this.config.selector = '.animate';
        this.selector = typeof this.config.selector === 'string' ? document.querySelectorAll(this.config.selector) : this.config.selector;

        if (this.selector) {
            this.init();
        }
    }


    updateTextField() {

        _map(this.selector, (item) => {
            if (!_empty(item.value)) {
                item.classList.add('focus');
            }
        });
    }

    init() {
        _map(this.selector, (item) => {
            let input = item.querySelector('input') || item.querySelector('textarea');
            if (input === null) {
                return false;
            }

            input.addEventListener('focus', () => {
                item.classList.add('focus');
            });

            input.addEventListener('blur', (a) => {
                if (_empty(input.value)) {
                    item.classList.remove('focus');
                }
            });

            input.addEventListener('input', (a) => {
                item.classList.add('focus');
            });
            if (input.value) {
                input.dispatchEvent(new Event('focus'));

            }
        });
    }
}
