import FormText from "../Components/inputs/text";
import React, {useState} from 'react';
import FormPassword from "../Components/inputs/password";
import Button from "../../../Components/button";
import ErrorBag from "../../../Components/ErrorBag";
import Link from "../../../Components/Link";

const axios = require('axios');

export default function Login(props) {
    const {handleChange, title, changeStatus, status} = props;
    const {email, password, addParam} = props.values;
    const showButton = password.length >= 1 && email.length;
    const [loading, setLoading] = useState(false);
    const [errorBag, setErrorBag] = useState({});

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorBag({});

        axios.post(window.login,
            {
                ...{
                    email: email,
                    password: password,
                }, ...addParam
            })
            .then(function (response) {
                if (response.data) {
                    if (response.data.hasOwnProperty('errors')) {
                        setErrorBag(response.data.errors);
                    }

                    if (response.data.hasOwnProperty('url')) {
                        window.location.href = response.data.url;
                        return false;
                    }
                }
                setLoading(false);
                // TODO if response is not url
            }).catch(error => {
            setLoading(false);
            if (error.response) {
                let data = error.response.data;
                setErrorBag(data.errors);
            }
        });
    };

    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <div className="px-30 pt-10 pb-30 bg-primary-60 popup-content-wrapper">
                    <div className="my-20">
                        <h1 className="text--h1 text-white font-weight-bold">
                            {title}
                        </h1>
                    </div>

                    <ErrorBag errors={errorBag}/>

                    <div className="my-20">
                        <div className="my-10">
                            <FormText
                                onChange={handleChange}
                                title="E-mail"
                                value={email}
                                name="email"
                                type="email"
                            />
                        </div>

                        <div className="my-10">
                            <FormPassword
                                onChange={handleChange}
                                title="Wachtwoord"
                                value={password}
                                name="password"
                            />
                        </div>
                    </div>

                    <div className="my-20">
                        <Link
                            onClick={() => changeStatus('password-reset')}
                            title="Wachtwoord vergeten"
                            arrow={true}
                            color="white"
                        />
                    </div>

                    <div className="my-20">
                        <Link
                            onClick={() => changeStatus('register')}
                            title="Nog geen account? Maak een account aan."
                            arrow={true}
                            color="white"
                        />
                    </div>
                </div>

                <div className="link-holder text-center mt-n20">
                    <div className="bg-white d-inline-block">
                        <Button
                            title="Inloggen"
                            color="yellow"
                            disabled={!showButton}
                            type="submit"
                            arrow={true}
                            isLoading={loading}
                        />
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}
