import React from "react";
import _size from 'lodash/size'
import _map from 'lodash/map'
import Alert from "./Alert";

export default function ErrorBag(props) {
    const {errors} = props;

    if (_size(errors) === 0) {
        return <React.Fragment/>;
    }

    const displayError = (error) => {
        return _map(error, displayItem)
    };

    const displayItem = (error) => {
        return (
            <div className="my-5" key={error}>
                <small>
                    {error}
                </small>
            </div>
        )
    };

    return (
        <Alert message={displayError(errors)} color="red" isActive={true}/>
    )
}
