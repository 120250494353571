import React from 'react';

export default function Popup(props) {
    const {active, setActive} = props;
    if (!active) {
        return <React.Fragment/>
    }

    return (
        <React.Fragment>
            <div className="popup">
                <div className="popup__underlay" onClick={() => setActive(false)}/>
                <div className="popup__dialog">
                    <div className="popup__content">
                        <div className="popup__close" onClick={() => setActive(false)}>{props?.closeIcon || 'X'}</div>
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
