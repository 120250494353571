import React, {useState} from 'react';
import {InputWrapper} from "./Wrapper";

const _map  = require('lodash/map');

export default function FormSelect(props) {

    const [focus, setFocus] = useState(false);
    const {name, title, placeholder, onChange, values, className, value} = props;

    return (
        <InputWrapper name={name} title={title} isFocused={focus || value.length}>
            <select
                id={name}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                className={className}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                defaultValue={value}
            >
                <option disabled={true} value="" />
                {
                    _map(values, (value, key) => {
                        return <option value={value} key={key + value}>{value}</option>
                    })
                }
            </select>
        </InputWrapper>
    )
}
