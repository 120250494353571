export function BaseUrl(uri) {
    return window.baseUrl + `/${uri}`;
}

export function ImageUrl(name) {
    return window.baseUrl + `/images/${name}`;
}

export function UploadUrl(name) {
    return window.baseUrl + `/uploads/${name}`;
}

export function AddWindowUrlParams(url) {
    return `${url}` + window.location.search;
}

export function AddReturnUrlParam(url, returnPath) {
    return `${url}` + `&return_url=${returnPath}`
}
