import React, {useEffect, useState} from "react";

export const FavoriteContext = React.createContext({});
export const ComparisonContext = React.createContext({});

const axios = require('axios');

export function UserProvider(props) {
    let defaultFavoriteContext = useState({});
    const [favoriteContext, setFavoriteContext] = defaultFavoriteContext;

    let defaultComparisonContext = useState({});
    const [comparisonContext, setComparisonContext] = defaultComparisonContext;

    useEffect(() => {
        axios.post(document.baseURI + "react/info").then((data) => {
            setFavoriteContext(data.data.favorites);
            setComparisonContext(data.data.comparisons);
        });
    }, []);

    return (
        <div>
            <FavoriteContext.Provider value={defaultFavoriteContext}>
                <ComparisonContext.Provider value={defaultComparisonContext}>
                    <div>
                        {props.children}
                    </div>
                </ComparisonContext.Provider>
            </FavoriteContext.Provider>
        </div>
    )
}
