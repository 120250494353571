import React, {useState} from 'react';
import Login from "./login";
import Register from "./register";
import PasswordReset from "./password-reset";
import PasswordEdit from "./password-edit";

export default function LoginApp(props) {
    const [status, setStatus] = useState(props.view ? props.view : 'login');
    const [user, setUser] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        firstname: '',
        lastname: '',
        terms: false,
        newsletter: false,
        program_area_1: '',
        program_area_2: '',
        zipcode: '',
        addParam: props.addParam ? props.addParam : {}
    });
    const handleChange = (event) => setUser({...user, ...{[event.target.name]: event.target.value}});
    const handleClick = (key, value) => setUser({...user, ...{[key]: value}});

    const changeStatus = (newStatus) => {
        setStatus(newStatus);
    };

    return (
        <div>
            {status === 'login' && <Login
                values={user}
                title='Inloggen'
                handleChange={handleChange}
                changeStatus={changeStatus}
            />}

            {status === 'login-favorite' && <Login
                values={user}
                title='Log in of registreer om je favorieten op te slaan en te vergelijken'
                handleChange={handleChange}
                changeStatus={changeStatus}
            />}

            {status === 'login-comparison' && <Login
                values={user}
                title='Log in of registreer om je favorieten op te slaan en te vergelijken'
                handleChange={handleChange}
                changeStatus={changeStatus}
            />}

            {status === 'register' && <Register
                values={user}
                handleChange={handleChange}
                changeStatus={changeStatus}
                handleClick={handleClick}
            />}

            {status === 'password-reset' && <PasswordReset
                title='Iedereen is wel eens een wachtwoord kwijt...'
                values={user}
                handleChange={handleChange}
                changeStatus={changeStatus}
                handleClick={handleClick}
            />}

            {status === 'password-edit' && <PasswordEdit
                title='Wachtwoord wijzigen'
                values={user}
                handleChange={handleChange}
                changeStatus={changeStatus}
                handleClick={handleClick}
            />}

        </div>
    )
}
