import React from 'react';
import {UserProvider} from "../../../Context/userProvider";
import AddToFavoriteHorizontal from "../../../Components/AddToFavoriteHorizontal";
import AddToComparisonHorizontal from "../../../Components/AddToComparisonHorizontal";

export default function AddProgram({id, type}) {
    return (
        <div>
            <UserProvider>
                <div className="row">
                    <div className="col-auto">
                        <AddToFavoriteHorizontal id={id} type={type}/>
                    </div>
                    <div className="col-auto">
                        <AddToComparisonHorizontal id={id} type={type}/>
                    </div>
                </div>
            </UserProvider>
        </div>
    )
}
