import React from 'react';

export default function FormCheckbox(props) {

    const {onClick, value, label, name, color, border} = props;

    return (
        <div onClick={onClick}
             className={`input-group input-group__checkables ${border}`}
        >
            <div className="input-group__body my-10">
                <input type="checkbox"
                       onChange={() => {
                       }}
                       name={name}
                       checked={value}/>
                <label htmlFor="" className={color}>
                    {label}
                </label>
            </div>
        </div>
    )
}
