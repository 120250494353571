import React, {useState} from 'react';
import {InputWrapper} from "./Wrapper";
import {ImageUrl} from "../../../../Helpers/Url";

export default function FormPassword(props) {
    const [focus, setFocus] = useState(false);
    const [eyeClicked, setEyeClicked] = useState(false);
    const {name, title, placeholder, onChange, value, className, color, border} = props;
    const type = eyeClicked ? 'text' : 'password';

    return (
        <InputWrapper name={name} title={title} nam isFocused={focus || value.length} color={color} border={border}>
            <div className="password-toggle">
                <input
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    className={className}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />

                <div className="password-toggle__eye"
                     onMouseDown={() => setEyeClicked(true)}
                     onMouseUp={() => setEyeClicked(false)}>
                    <img src={ImageUrl('icons/password-eye.svg')} alt="" className="image__fluid"/>
                </div>
            </div>
        </InputWrapper>
    )
}
